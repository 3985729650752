/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */
/* eslint-disable spaced-comment */

import { PureComponent } from 'react';
import { Page } from 'Component/Header/Header.config';
import ChevronIcon from 'Component/ChevronIcon';
import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';
import Link from 'Component/Link';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import './AsapDelivery.style';

/**
 * AsapDelivery
 * @class AsapDelivery
 /* @namespace Component/AsapDelivery/Component */

 export class AsapDeliveryComponent extends PureComponent{
    render() {
        const {
            isAsSoonasPossible,
            is_details
        } = this.props;
        
        if(!isAsSoonasPossible){
            return null
        }
        
        if(Page.PDP && is_details){
            const url =  "#";
            
            return (
                <div block="AsapDelivery" elem="PDPContainer">
                    <div block="AsapDelivery" elem="PDPWrapper">
                        <div block="AsapDelivery" elem="Content">
                            <div block="AsapDelivery" elem="Title">
                                { __('Eligible for Radian ASAP Delivery') }
                            </div>
                            <div block="AsapDelivery" elem="SmallContent">
                                { __('Express order delivery within Lusaka City.') }
                            </div>
                        </div>
                        <div block="AsapDelivery" elem="LearnMore">
                            <Link
                                block="AsapDelivery"
                                elem="Link"
                                to={ url }
                            >
                                { __('Learn More') } <ChevronIcon direction={ Directions.RIGHT } />
                            </Link>
                        </div>
                    </div> 
                </div>
            )
        }

        return (
            <div block="AsapDelivery" elem="Wrapper">
                <div block="AsapDelivery" elem="Icon">
                    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.75 5H1.25C1.09375 5 1 4.90625 1 4.75V4.25C1 4.125 1.09375 4 1.25 4H8.75C8.875 4 9 4.125 9 4.25V4.75C9 4.90625 8.875 5 8.75 5ZM19.75 11C19.875 11 20 11.125 20 11.25V11.75C20 11.9062 19.875 12 19.75 12H17.9375C17.9688 12.1875 18 12.3438 18 12.5C18 13.9062 16.875 15 15.5 15C14.0938 15 13 13.9062 13 12.5C13 12.3438 13 12.1875 13.0312 12H6.9375C6.96875 12.1875 7 12.3438 7 12.5C7 13.9062 5.875 15 4.5 15C3.09375 15 2 13.9062 2 12.5V8H3V10.5312C3.40625 10.2188 3.90625 10 4.5 10C5.3125 10 6.03125 10.4062 6.46875 11H12V1.46875C12 1.21875 11.75 1 11.4375 1H3.53125C3.21875 1 3 1.21875 3 1.46875V2H7.75C7.875 2 8 2.125 8 2.25V2.75C8 2.90625 7.875 3 7.75 3H0.25C0.09375 3 0 2.90625 0 2.75V2.25C0 2.125 0.09375 2 0.25 2H2V1.46875C2 0.65625 2.6875 0 3.53125 0H11.4375C12.2812 0 13 0.65625 13 1.46875V3H14.375C14.9062 3 15.4062 3.21875 15.7812 3.59375L18.4062 6.21875C18.7812 6.59375 19 7.125 19 7.625V11H19.75ZM4.5 14C5.3125 14 6 13.3438 6 12.5C6 11.6875 5.3125 11 4.5 11C3.65625 11 3 11.6875 3 12.5C3 13.3438 3.65625 14 4.5 14ZM13 4V7.03125H17.75C17.7188 7 17.7188 6.96875 17.6875 6.9375L15.0625 4.3125C14.875 4.125 14.625 4 14.375 4H13ZM15.5 14C16.3125 14 17 13.3438 17 12.5C17 11.6875 16.3125 11 15.5 11C14.6562 11 14 11.6875 14 12.5C14 13.3438 14.6562 14 15.5 14ZM18 11V8H13V11H13.5C13.9688 10.4062 14.6875 10 15.5 10C16.3125 10 17.0312 10.4062 17.4688 11H18ZM8 6.75C8 6.90625 7.875 7 7.75 7H0.25C0.09375 7 0 6.90625 0 6.75V6.25C0 6.125 0.09375 6 0.25 6H7.75C7.875 6 8 6.125 8 6.25V6.75Z" fill="#005B92"/>
                    </svg>
                </div>
                <div block="AsapDelivery" elem="Content">
                    { __('Eligible for Radian ASAP Delivery') }
                </div>
            </div>
        );
    }
 }

 export default AsapDeliveryComponent;
