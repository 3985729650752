/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */
/* eslint-disable spaced-comment */

import { ProductCardComponent as SourceProductCardComponent } from "SourceComponent/ProductCard/ProductCard.component";
import AsapDelivery  from "Component/AsapDelivery"
import Html from 'Component/Html';
import Loader from 'Component/Loader';
import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';

import './ProductCard.override.style';
/**
 * Product card
 * @class ProductCard
 /* @namespace Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCardComponent {

  renderVisibleOnHover() {
    const { device } = this.props;

    // if (device.isMobile) {
    //     return null;
    // }

    return (
        <>
            { this.renderConfigurableOptions() }
            <div block="ProductCard" elem="Footer">
                { this.renderAddToCart() }
            </div>
        </>
    );
  }

  renderShortDescription() {
    const { product: { short_description } } = this.props;
    const { html } = short_description || {};

    if (!html) {
      return null;
    }

    return (
      <div block="ProductCard" elem="ShortDescription">
        <Html content={ html } />
      </div>
    );
  }
  

  renderCardContent() {
    const {
      renderContent,
      product,
      isMobile,
    } = this.props;

    const { name } = product;

    if (renderContent) {
      return renderContent(this.contentObject);
    }

    return this.renderCardLinkWrapper(
      <div
        block="ProductCard"
        elem="LinkInnerWrapper"
        mods={{ loaded: !!name }}
      >
        <div block="ProductCard" elem="FigureReview">
          <figure block="ProductCard" elem="Figure">
            {isMobile && (
              <div block="ProductCard" elem="MobileActions">
                {this.renderProductCardWishlistButton()}
                {this.renderProductCompareButton()}
              </div>
            )}
            {this.renderPicture()}
          </figure>
          { this.renderProductActions() }
        </div>
        <div block="ProductCard" elem="Content">
          {this.renderName(false)}
          {this.renderReviews()}
          {this.renderBrand()}
          {this.renderPrice()}
          {this.renderShortDescription()}
          <AsapDelivery product={product} />
        </div>
        {this.renderVisibleOnHover()}
      </div>
    );
  }

  renderCardListContent() {
    const {
      children,
      layout,
      renderContent,
      product,
      product: { name },
    } = this.props;

    if (renderContent) {
      return renderContent(this.contentObject);
    }

    return this.renderCardLinkWrapper(
      <div block="ProductCard" elem="Link">
        <div block="ProductCard" elem="FigureReview">
          <figure block="ProductCard" elem="Figure">
            {this.renderPicture()}
          </figure>
          
        </div>
        <div block="ProductCard" elem="Content" mods={{ layout }}>
          <div block="ProductCard" elem="MainInfo">
            {this.renderMainDetails()}
            {this.renderReviews()}
            {this.renderBrand()}
          </div>
          <div block="ProductCard" elem="AttributeWrapper">
            {this.renderPrice()}
            {this.renderShortDescription()}
            <AsapDelivery product={product} />
            {this.renderConfigurableOptions()}
          </div>
          <div
            block="ProductCard"
            elem="ActionWrapper"
            mods={{ loaded: !!name }}
          >
            {this.renderAddToCart()}
            {this.renderProductActions()}
          </div>
          <div block="ProductCard" elem="AdditionalContent">
            {children}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
        children,
        mix,
        isLoading,
        layout,
    } = this.props;

    if (layout === CategoryPageLayout.LIST) {
        return (
            <div
              block="ProductCard"
              mods={ { layout } }
              mix={ mix }
            >
                <Loader isLoading={ isLoading } />
                { this.renderCardListContent() }
            </div>
        );
    }

    return (
        <div
          block="ProductCard"
          mods={ { layout } }
          mix={ mix }
        >
            <Loader isLoading={ isLoading } />
            { this.renderCardContent() }
            <div block="ProductCard" elem="AdditionalContent">
                { children }
            </div>
        </div>
    );
  }



}

export default ProductCardComponent;
